import axios from "~/plugins/axios";
import CatalogService from "~/service/catalogService";

export default {
  getAllAddress() {
    return axios
      .get("/ebsn/api/eurospin-b2b-registration/list-all", {
        params: {}
      })
      .then(data => {
        return data.data.data.addresses;
      });
  },
  updateAddress(address) {
    return axios
      .post("/ebsn/api/eurospin-b2b-registration/update", address)
      .then(data => {
        if (data.data.response.status == 0) {
          global.EventBus.$emit("success", {
            message: "addresses.setAddress"
          });
          return data.data.data;
        } else {
          return false;
        }
      });
  },
  getProvinces(countryId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },

  registerUser(user) {
    let url = "/ebsn/api/eurospin-b2b-registration/register_user";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => err);
  },

  registerUserWithCard(user) {
    let url = "/ebsn/api/eurospin-b2b-registration/register_user_with_card";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => err);
  },
  checkCard(user) {
    return axios
      .get("/ebsn/api/eurospin-b2b-registration/lookup_user", {
        params: { card: user.fidelityCard, piva_cf: user.person.fiscalCode }
      })
      .then(data => data.data)
      .catch(error => error);
  },
  registerPerson(user) {
    let url = "/ebsn/api/eurospin-b2b-registration/register_person";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  getResetPasswordToken(email) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  confirmRegistration(email, controlCode, newEmail) {
    let url = "/ebsn/api/registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: controlCode,
          new_email: newEmail
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(data => {
        return data.data;
      });
  },
  submitTicket(form) {
    let url = "/ebsn/api/salesforce-ticket/send_ticket";
    return axios
      .post(url, form)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  submitOrderTicket(form) {
    let url = "/ebsn/api/salesforce-ticket/send_order_ticket";
    return axios
      .post(url, form)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  subscribeNewsletter(form) {
    let url = "ebsn/api/salesforce-ticket/subscribe_newsletter";
    return axios
      .post(url, form)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  subscriptionPlanList() {
    let url = "/ebsn/api/subscription-plan/list";
    return axios
      .get(url)
      .then(response => {
        return response.data.data;
      })
      .catch(error => error);
  },
  userSubscriptionPlanList() {
    let url = "/ebsn/api/subscription-plan/list?details=true";
    return axios
      .get(url)
      .then(response => {
        return response.data.data;
      })
      .catch(error => error);
  },
  addSubscriptionPlan(subscriptionPlan, userId) {
    let url = "/ebsn/api/subscription-plan/add";
    console.log(userId);
    let params = {
      subscriptionPlanId: subscriptionPlan.subscriptionPlanId
    };
    /*let params = {
      subscriptionPlan: {
        subscriptionPlanId: subscriptionPlan.subscriptionPlanId
      },
      userId: userId,
      startDate: new Date(subscriptionPlan.startDate),
      endDate: new Date(subscriptionPlan.endDate),
      active: 1
    };*/

    return axios
      .post(url, params)
      .then(response => {
        if (response && response.data) {
          const fullName = CatalogService.getProductFullnameForToast(
            subscriptionPlan.product
          );
          const quantity = 1;

          const unit = CatalogService.getProductUnitForToast(
            subscriptionPlan.product
          );
          if (
            response?.data?.response?.errorsMessage.length === 0 &&
            response?.data?.response?.infosMessage.length === 0 &&
            response?.data?.response?.warningsMessage.length === 0
          ) {
            global.EventBus.$emit("success", {
              message: global.vm.$t("cart.productAddText", {
                quantity,
                unit,
                name: fullName
              })
            });
          }
        }
        return response.data;
      })
      .catch(error => error);
  },
  deleteSubscriptionPlan(subscriptionPlan, userId) {
    let url = "/ebsn/api/subscription-plan/delete";
    console.log(userId);
    /*let params = {
      subscriptionPlan: {
        subscriptionPlanId: subscriptionPlan.subscriptionPlanId
      },
      subscriptionPlanUserId: subscriptionPlan.subscriptionPlanUserId,
      userId: userId,
      startDate: subscriptionPlan.startDate,
      endDate: subscriptionPlan.endDate,
      active: subscriptionPlan.active
    };*/
    return axios
      .post(url, subscriptionPlan)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  }
};
